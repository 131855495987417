import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"border":"0 !important"}
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-2" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_8 = { class: "svg-icon svg-icon-2" }
const _hoisted_9 = {
  class: "card-body pt-0",
  id: "divToPrint"
}
const _hoisted_10 = { class: "text-gray-800 text-hover-primary mb-1" }
const _hoisted_11 = ["value"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_KTNewDeviceModal = _resolveComponent("KTNewDeviceModal")!
  const _component_KTPdfModal = _resolveComponent("KTPdfModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-primary ms-5" }, "Invoice", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "border border-primary bg-light rounded-2" }, [
              _createElementVNode("input", {
                type: "text",
                "data-kt-subscription-table-filter": "search",
                class: "form-control form-control-solid w-250px ps-14",
                placeholder: "Search"
              })
            ], -1 /* HOISTED */))
          ]),
          _createCommentVNode("end::Search")
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _createElementVNode("div", _hoisted_6, [
          _createCommentVNode("begin::Toolbar"),
          (_ctx.permissions.includes('create-invoice'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createCommentVNode("begin::Add subscription"),
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.createInvoice && _ctx.createInvoice(...args)))
                }, [
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
                  ]),
                  _cache[2] || (_cache[2] = _createTextVNode(" Create Invoice "))
                ]),
                _createCommentVNode("end::Add subscription")
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("end::Toolbar"),
          _createCommentVNode("begin::Group actions"),
          _createCommentVNode("end::Group actions")
        ]),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.invoices,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange
        }, {
          "cell-id": _withCtx(({ row: invoice }) => [
            _createElementVNode("div", _hoisted_10, " 0000" + _toDisplayString(invoice.id), 1 /* TEXT */)
          ]),
          "cell-se_account_id": _withCtx(({ row: invoice }) => [
            _createTextVNode(_toDisplayString(invoice.se_account_id
              ? `${
                  _ctx.customers.filter((c) => c.id == invoice.se_account_id)[0]
                    ?.name
                }` +
                " (ID: " +
                invoice.se_account_id +
                ")"
              : "N/A") + " ", 1 /* TEXT */),
            _createCommentVNode(" <div class=\"badge badge-light\">{{ invoice.se_account_id }}</div> ")
          ]),
          "cell-invoice_date": _withCtx(({ row: invoice }) => [
            _createTextVNode(_toDisplayString(_ctx.dateTime(invoice.invoice_date)), 1 /* TEXT */)
          ]),
          "cell-due_date": _withCtx(({ row: invoice }) => [
            _createTextVNode(_toDisplayString(_ctx.dateTime(invoice.due_date)), 1 /* TEXT */)
          ]),
          "cell-delivery_date": _withCtx(({ row: invoice }) => [
            _createTextVNode(_toDisplayString(_ctx.dateTime(invoice.delivery_date)), 1 /* TEXT */)
          ]),
          "cell-po_number": _withCtx(({ row: invoice }) => [
            _createTextVNode(_toDisplayString(invoice.po_number), 1 /* TEXT */)
          ]),
          "cell-status": _withCtx(({ row: invoice }) => [
            _createCommentVNode(" {{ invoice.status }} "),
            _createElementVNode("select", {
              name: "timezone",
              value: invoice.status,
              "aria-label": "Select a Timezone",
              "data-control": "select2",
              "data-placeholder": "date_period",
              class: "form-select form-select-sm form-select-solid"
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("option", { value: "0" }, "Un Paid", -1 /* HOISTED */),
              _createElementVNode("option", { value: "1" }, "Paid", -1 /* HOISTED */),
              _createElementVNode("option", { value: "2" }, "Partially Paid", -1 /* HOISTED */)
            ]), 8 /* PROPS */, _hoisted_11)
          ]),
          "cell-action": _withCtx(({ row: invoice }) => [
            _createTextVNode(_toDisplayString(invoice.action) + " ", 1 /* TEXT */),
            (_ctx.permissions.includes('download-invoice'))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  class: "text-gray-600 text-hover-primary mx-1",
                  title: "Download",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#kt_modal_pdf",
                  onClick: ($event: any) => (_ctx.openInvoice(invoice))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("div", {
                    class: _normalizeClass(`badge badge-light-success`)
                  }, [
                    _createElementVNode("i", { class: "fas fa-download text-success" })
                  ], -1 /* HOISTED */)
                ]), 8 /* PROPS */, _hoisted_12))
              : _createCommentVNode("v-if", true),
            (_ctx.permissions.includes('update-invoice'))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  onClick: ($event: any) => (_ctx.editInvoice(invoice)),
                  class: "text-gray-600 text-hover-primary mx-1",
                  title: "Edit"
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("div", {
                    class: _normalizeClass(`badge badge-light-success`)
                  }, [
                    _createElementVNode("i", { class: "fas fa-edit text-success" })
                  ], -1 /* HOISTED */)
                ]), 8 /* PROPS */, _hoisted_13))
              : _createCommentVNode("v-if", true),
            (_ctx.permissions.includes('delete-invoice'))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  href: "#",
                  class: "text-gray-600 text-hover-primary mb-1",
                  title: "Delete",
                  onClick: ($event: any) => (_ctx.deleteDevice(invoice.id))
                }, _cache[6] || (_cache[6] = [
                  _createElementVNode("div", {
                    class: _normalizeClass(`badge badge-light-success`)
                  }, [
                    _createElementVNode("i", { class: "fas fa-trash text-danger" })
                  ], -1 /* HOISTED */)
                ]), 8 /* PROPS */, _hoisted_14))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"])
      ]),
      _createCommentVNode("end::Card body")
    ]),
    _createCommentVNode("end::Card"),
    _createVNode(_component_KTNewDeviceModal),
    _createVNode(_component_KTPdfModal, {
      invo: _ctx.selectedInvoice,
      customer: _ctx.customer
    }, null, 8 /* PROPS */, ["invo", "customer"])
  ], 64 /* STABLE_FRAGMENT */))
}