import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/img/bins/logo.png'


const _hoisted_1 = {
  class: "modal fade",
  ref: "submitButtonRef",
  id: "kt_modal_pdf",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-800px" }
const _hoisted_3 = { class: "modal-content roundeda" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { id: "invoiceTemplate" }
const _hoisted_6 = { class: "infocicehearder" }
const _hoisted_7 = { class: "invoicewraper" }
const _hoisted_8 = { class: "listcontiar" }
const _hoisted_9 = { class: "list1" }
const _hoisted_10 = { class: "no-bullets" }
const _hoisted_11 = { class: "list2" }
const _hoisted_12 = { class: "no-bullets" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 0,
  class: "sensor headings"
}
const _hoisted_15 = {
  key: 1,
  class: "tablediv"
}
const _hoisted_16 = { key: 2 }
const _hoisted_17 = {
  key: 3,
  class: "tablediv"
}
const _hoisted_18 = { key: 4 }
const _hoisted_19 = {
  key: 5,
  class: "tablediv"
}
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-lg-6 col-sm-5 ml-auto" }
const _hoisted_22 = { class: "table table-clear" }
const _hoisted_23 = { class: "text-dark" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "text-dark" }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "text-dark" }
const _hoisted_28 = { key: 2 }
const _hoisted_29 = { class: "text-dark" }
const _hoisted_30 = { id: "footer" }
const _hoisted_31 = { class: "halfdown" }
const _hoisted_32 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin:Modal - New Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin:Modal dialog"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin:Modal content"),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              style: {"position":"fixed"},
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.printDocument && _ctx.printDocument(...args))),
              "mat-raised-button": "",
              class: "btn btn-secondary action"
            }, " Save ")
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"headerwrapper\" style=\"border-bottom:1px solid gray;\" data-v-40c796c4><div class=\"list11\" data-v-40c796c4><ul class=\"no-bullets\" data-v-40c796c4><li data-v-40c796c4><b data-v-40c796c4>Information</b></li><li data-v-40c796c4>Smartends BV</li><li data-v-40c796c4>+32 (0) 487 59 51 22</li><li data-v-40c796c4>Zafar@smartends.com</li></ul></div><div class=\"list22\" style=\"float:left;\" data-v-40c796c4><ul class=\"no-bullets\" data-v-40c796c4><li data-v-40c796c4><b data-v-40c796c4>Company Address</b></li><li data-v-40c796c4>Jules de Saint-Genoisstraat 123</li><li data-v-40c796c4>9050 Gent</li><li data-v-40c796c4>BE 0673.512.273</li></ul></div><div style=\"float:right;\" data-v-40c796c4><img src=\"" + _imports_0 + "\" data-v-40c796c4></div></div>", 1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "invoice" }, "INVOICE", -1 /* HOISTED */)),
                _createElementVNode("h3", null, "Number: 0000" + _toDisplayString(_ctx.invoice?.id), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("ul", _hoisted_10, [
                  _cache[8] || (_cache[8] = _createElementVNode("li", null, [
                    _createElementVNode("b", null, "About:")
                  ], -1 /* HOISTED */)),
                  _createElementVNode("li", null, [
                    _cache[2] || (_cache[2] = _createElementVNode("b", null, "Client number:", -1 /* HOISTED */)),
                    _createTextVNode(" 0000" + _toDisplayString(_ctx.invoice?.se_account_id), 1 /* TEXT */)
                  ]),
                  _createElementVNode("li", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("b", null, "Date: ", -1 /* HOISTED */)),
                    _createTextVNode(" " + _toDisplayString(_ctx.dateTime(_ctx.invoice?.invoice_date)), 1 /* TEXT */)
                  ]),
                  _createElementVNode("li", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("b", null, "Delivery: ", -1 /* HOISTED */)),
                    _createTextVNode(_toDisplayString(_ctx.dateTime(_ctx.invoice?.delivery_date)), 1 /* TEXT */)
                  ]),
                  _createElementVNode("li", null, [
                    _cache[5] || (_cache[5] = _createElementVNode("b", null, "Due date: ", -1 /* HOISTED */)),
                    _createTextVNode(" " + _toDisplayString(_ctx.dateTime(_ctx.invoice?.due_date)), 1 /* TEXT */)
                  ]),
                  _createElementVNode("li", null, [
                    _cache[6] || (_cache[6] = _createElementVNode("b", null, "IBAN:", -1 /* HOISTED */)),
                    _createTextVNode(" " + _toDisplayString(_ctx.invoice?.currency == "Euro"
                      ? "BE70736067828225"
                      : "IE37CITI99005170806404"), 1 /* TEXT */)
                  ]),
                  _createElementVNode("li", null, [
                    _cache[7] || (_cache[7] = _createElementVNode("b", null, "BIC:", -1 /* HOISTED */)),
                    _createTextVNode(" " + _toDisplayString(_ctx.invoice?.currency == "Euro" ? "KREDBEBB" : "CITIIE2X"), 1 /* TEXT */)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("ul", _hoisted_12, [
                  _createElementVNode("li", null, _toDisplayString(_ctx.company?.name || "N/A"), 1 /* TEXT */),
                  _createElementVNode("li", null, _toDisplayString(_ctx.company?.contact_person || "N/A"), 1 /* TEXT */),
                  _createElementVNode("li", {
                    innerHTML: _ctx.company?.billing_address
                  }, null, 8 /* PROPS */, _hoisted_13),
                  _createElementVNode("li", null, _toDisplayString(_ctx.company.email), 1 /* TEXT */)
                ])
              ])
            ]),
            _cache[20] || (_cache[20] = _createElementVNode("div", {
              class: "headings",
              style: {"margin-bottom":"55px"}
            }, [
              _createElementVNode("p", { style: {"color":"white"} }, ".")
            ], -1 /* HOISTED */)),
            (_ctx.list.sensorList?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[9] || (_cache[9] = [
                  _createElementVNode("h3", null, "Sensor Charges", -1 /* HOISTED */)
                ])))
              : _createCommentVNode("v-if", true),
            (_ctx.list.sensorList?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("table", null, [
                    _cache[10] || (_cache[10] = _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "Product"),
                      _createElementVNode("th", null, "Quantity"),
                      _createElementVNode("th", null, "Unit price"),
                      _createElementVNode("th", null, "%age Payable now"),
                      _createElementVNode("th", null, "VAT %"),
                      _createElementVNode("th", null, "Total Price"),
                      _createElementVNode("th", null, "Payable now"),
                      _createElementVNode("th", null, "Payable Including Vat")
                    ], -1 /* HOISTED */)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.sensorList, (item, i) => {
                      return (_openBlock(), _createElementBlock("tr", { key: i }, [
                        _createElementVNode("td", null, _toDisplayString(item.product), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(item.quantity), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(item.unit_price), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(item.percentage || "0") + " %", 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.vat), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.total_price), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.payable_now), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.total_incl_vat), 1 /* TEXT */)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.list.shipmentList?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[11] || (_cache[11] = [
                  _createElementVNode("h3", { class: "headings" }, "Shipment Charges", -1 /* HOISTED */)
                ])))
              : _createCommentVNode("v-if", true),
            _createCommentVNode("Third table "),
            (_ctx.list.shipmentList?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("table", null, [
                    _cache[12] || (_cache[12] = _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "Description"),
                      _createElementVNode("th", null, "Shipment cost"),
                      _createElementVNode("th", null, "%age Payable now"),
                      _createElementVNode("th", null, "VAT %"),
                      _createElementVNode("th", null, "Total Price"),
                      _createElementVNode("th", null, "Payable now"),
                      _createElementVNode("th", null, "Payable Including Vat")
                    ], -1 /* HOISTED */)),
                    _createCommentVNode(" {{ getCurrency(invoice?.currency) }} "),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.shipmentList, (item, i) => {
                      return (_openBlock(), _createElementBlock("tr", { key: i }, [
                        _createElementVNode("td", null, _toDisplayString(item.description), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + " " + _toDisplayString(item.shipment_cost), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(item.percentage) + " %", 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(item.vat), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.total_price), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + " " + _toDisplayString(item.payable_now), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.total_incl_vat), 1 /* TEXT */)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode("table finish"),
            _createCommentVNode("second table "),
            (_ctx.list.platformList?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[13] || (_cache[13] = [
                  _createElementVNode("h3", { class: "headings" }, "Platform Charges", -1 /* HOISTED */)
                ])))
              : _createCommentVNode("v-if", true),
            (_ctx.list.platformList?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("table", null, [
                    _cache[14] || (_cache[14] = _createElementVNode("tr", null, [
                      _createElementVNode("th", null, "Total Sensors"),
                      _createElementVNode("th", null, "Monthly Subs"),
                      _createElementVNode("th", null, "Number of Months"),
                      _createElementVNode("th", null, "Total Subs Charges"),
                      _createElementVNode("th", null, "Total Excl. VAT"),
                      _createElementVNode("th", null, "VAT%"),
                      _createElementVNode("th", null, "Totoal incl. VAT"),
                      _createElementVNode("th", null, "Dis. %"),
                      _createElementVNode("th", null, "Perc. %"),
                      _createElementVNode("th", null, "Platform Total")
                    ], -1 /* HOISTED */)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.platformList, (item, i) => {
                      return (_openBlock(), _createElementBlock("tr", { key: i }, [
                        _createElementVNode("td", null, _toDisplayString(item.total_sensor), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + " " + _toDisplayString(item.charges_per_month), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(item.no_of_months), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.total_subs_charges), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.total_excl_vat), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(item.vat), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.total_incl_vat), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(item.discount), 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(item.percentage) + " %", 1 /* TEXT */),
                        _createElementVNode("td", null, _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)) + _toDisplayString(item.total_price), 1 /* TEXT */)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("table", _hoisted_22, [
                  _createElementVNode("tbody", null, [
                    _createElementVNode("tr", null, [
                      _cache[15] || (_cache[15] = _createElementVNode("td", null, [
                        _createElementVNode("strong", { class: "text-dark" }, "Invoice Total")
                      ], -1 /* HOISTED */)),
                      _createElementVNode("td", null, [
                        _createElementVNode("strong", _hoisted_23, _toDisplayString(_ctx.invoice?.price) + " " + _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)), 1 /* TEXT */)
                      ])
                    ]),
                    (_ctx.invoice?.ammount_received)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_24, [
                          _cache[16] || (_cache[16] = _createElementVNode("td", null, [
                            _createElementVNode("strong", { class: "text-dark" }, "Amount received to-date ")
                          ], -1 /* HOISTED */)),
                          _createElementVNode("td", null, [
                            _createElementVNode("strong", _hoisted_25, _toDisplayString(_ctx.invoice?.ammount_received) + " " + _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)), 1 /* TEXT */)
                          ])
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.invoice?.ammount_payable)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_26, [
                          _cache[17] || (_cache[17] = _createElementVNode("td", null, [
                            _createElementVNode("strong", { class: "text-dark" }, "Payable now ")
                          ], -1 /* HOISTED */)),
                          _createElementVNode("td", null, [
                            _createElementVNode("strong", _hoisted_27, _toDisplayString(_ctx.invoice?.ammount_payable) + " " + _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)), 1 /* TEXT */)
                          ])
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.invoice?.ammount_remaining)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_28, [
                          _cache[18] || (_cache[18] = _createElementVNode("td", null, [
                            _createElementVNode("strong", { class: "text-dark" }, "Payable Later ")
                          ], -1 /* HOISTED */)),
                          _createElementVNode("td", null, [
                            _createElementVNode("strong", _hoisted_29, _toDisplayString(_ctx.invoice?.ammount_remaining) + " " + _toDisplayString(_ctx.getCurrency(_ctx.invoice?.currency)), 1 /* TEXT */)
                          ])
                        ]))
                      : _createCommentVNode("v-if", true)
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", {
                class: "bottomtext",
                innerHTML: _ctx.invoice?.content
              }, null, 8 /* PROPS */, _hoisted_32)
            ])
          ])
        ]),
        _createCommentVNode("end:Modal content")
      ]),
      _createCommentVNode("end:Modal dialog")
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("end:Modal - New Card")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}